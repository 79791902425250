declare var webApp: any;
declare var mlf: any;

class HT {
    token: string;
    avatar: string;
}


(window as any).webApp = {};
webApp.pages = {};

webApp.hideItem = function (selector: string) {
    let item = document.getElementsByClassName(selector)[0];

    if (item.classList.contains("d-flex")) item.classList.remove("d-flex");

    item.classList.add("d-none");
}

class ValidQuestion {
    selector: string;
    isValid: boolean;
}

webApp.questionarioIsValid = function (questionObj: ValidQuestion)
{
    let validMessage = document.getElementById(questionObj.selector);    
    console.log(validMessage);
    if (validMessage != null) {

        if (questionObj.isValid) validMessage.classList.add('d-none');
        else validMessage.classList.remove('d-none');
    }


}
webApp.questionarioValidation = function (classValidation: string) {

    let messages = document.getElementsByClassName(classValidation);
    let messagesQuantity = messages.length;

    for (let i = 0; i < messagesQuantity; i++) {

        messages[i].classList.remove("d-none");
    }
}

class Cookie {
    key: string;
    value: string;
    days?: number;
    secure?: boolean;
    sameSite?: string;
}

webApp.addCookie = function (cookie: Cookie) {
    let date = new Date();
    date.setTime(date.getTime() + (cookie.days * 24 * 60 * 60 * 1000));
    document.cookie = `${cookie.key}=${cookie.value}; path=/; expires=${date.toUTCString()}; Secure=${cookie.secure}; SameSite=${cookie.sameSite}; Priority=Medium;`;
}

webApp.getCookies = function (key: string) {

    let cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) if (cookies[i].split("=")[0].trim() === key) return false

    return true;
}

webApp.getCookieValue = function (key: string) {
    let cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) if (cookies[i].split("=")[0].trim() === key) return cookies[i].split("=")[1]

    return "no";
}

webApp.log = function (message: any) {
    window.console.log(message);
}

webApp.initAceMenu = function () {
    $(document).ready(function () {
        ($("#respMenu") as any).aceResponsiveMenu({
            resizeWidth: '768', // Set the same in Media query
            animationSpeed: 'fast', //slow, medium, fast
            accoridonExpAll: false //Expands all the accordion menu on click
        });
    });
};

webApp.body = {};
webApp.body.getBodyElement = function () {
    return document.getElementsByTagName("body")[0];
}

webApp.body.addClass = function (cssClass: any) {
    var body = webApp.body.getBodyElement();

    if (!body.classList.contains(cssClass)) {
        body.classList.add(cssClass);
    }
}

webApp.body.removeClass = function (cssClass: any) {
    var body = webApp.body.getBodyElement();
    body.classList.remove(cssClass);
}

webApp.downloadFile = function (filename: any, bytesBase64: any) {
    var link = document.createElement('a');
    link.download = filename;
    link.href = "data:application/octet-stream;base64," + bytesBase64;
    document.body.appendChild(link); // Necessário para o Firefox
    link.click();
    document.body.removeChild(link);
}

function redirect(link: any) {
    alert(link);
}

mlf.resize = function () {
    function windowResize() {

        const innerHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
        const menuHeight = innerHeight - 314;
        const folioHeight = innerHeight - 48;

        const menu = document.querySelector(".scroll-menu");
        if (menuHeight >= 550) {
            menu.setAttribute("style", "display:block;height:550px;overflow:hidden;");
        } else {
            menu.setAttribute("style", "display:block;height:" + menuHeight + "px;");
            (menu as any).style.height = menuHeight + "px;";
        }

        const folio = document.querySelector(".outer-folio");
        folio.setAttribute("style", "display:block;height:" + folioHeight + "px;");
        (folio as any).style.height = folioHeight + "px;";

    }

    window.addEventListener("resize", function (event) {
        windowResize();
    })

    windowResize();
}

mlf.login = function () {
    function windowReposition() {
        try {
            const loginDiv = document.querySelector(".login-box-image");
            const randomNumber = Math.floor(Math.random() * 7 + 1);
            loginDiv.setAttribute("style", "background-image:url(_content/App.Client/assets/login/backgrounds/bg_login_0" + randomNumber + ".jpg) !important;");
            const cpf = document.querySelector("#cpf");
            (cpf as any).focus();
            (cpf as any).select();
            (loginDiv as any).visible = true;
        } catch (ex) {
            console.error(ex);
        }
    }

    windowReposition();
}

mlf.navigation = function (option: any) {

    const menu = document.querySelectorAll("#respMenu > li > a");
    for (let i = 0; i < menu.length; i++) {
        menu[i].classList.remove("active");
    }
    if (option == null || option == "") {
        option = "PaginaInicial";
    }
    const element = document.getElementById(option);

    element.classList.add("active");
}

mlf.cards = function (face: any, cardNumber: any) {

    $(document).ready(function () {

        const idFrente = "frente-" + cardNumber;
        const idVerso = "verso-" + cardNumber;

        const frente = document.getElementById(idFrente);
        const verso = document.getElementById(idVerso);

        if (face == 0) {
            frente.style.display = "none";
            verso.style.display = "flex";
        } else {
            verso.style.display = "none";
            frente.style.display = "flex";
        }
    });
}

class AppClient {
    async download(url: string) {
        window.location.href = url;
        return new Promise<void>(x => x());
    }
}

(window as any).AppClient = new AppClient();

// Inicialização da aplicação
function init() {
    // Handtalk
    const ht = new HT();
    ht.avatar = "HUGO";
    ht.token = "6351451a187d2ceb1373e770634d7298";
}
init();