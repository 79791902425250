declare const webApp: any;
import { Chart, registerables, ChartConfiguration } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import annotations from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(zoomPlugin);
Chart.register(ChartDataLabels);
Chart.register(annotations);
Chart.register(...registerables);

webApp.pages.index = {};
const IndexPage = webApp.pages.index;
(window as any).IndexPage = IndexPage;

IndexPage.chartIqv = function(chartData: any) {
    if (chartData.labels.length == 0) return;

    const data = {
        labels: chartData.labels,
        datasets: [
            {
                label: "Meu IQV",
                data: chartData.values,
                borderColor: "#7E5475",
                backgroundColor: "#7E5475",
                borderWidth: 1
            }
        ]
    };

    function getConfig(): ChartConfiguration<"line", number[], string> {
        return {
            type: "line",
            data: data,
            //maintainAspectRatio: false,
            options: {
                scales: {
                    y: {
                        min: 0,
                        max: 60,
                        display: false,
                    },
                },
                plugins: {
                    title: {
                        display: true,
                        text: "Minha evolução do Índice de Qualidade de Vida",
                        font: { size: 16 }
                    },
                    datalabels: {
                        formatter: function(value: any, context: any) {
                            if (context.dataset.label != "Meu IQV") return null;
                            if (context.dataIndex == 0) return value;
                            if (context.dataset.data[context.dataIndex - 1] != value) return value;
                            return null;
                        },
                        color: "#666f73",
                        anchor: "end",
                        align: "top"
                    },

                    annotation: {
                        annotations: {
                            line0: {
                                type: "line",
                                yMin: 0,
                                yMax: 0,
                                borderColor: "#00B050",
                                borderWidth: 2,
                                label: {
                                    backgroundColor: "rgba(240, 240, 240)",
                                    color: "#00B050",
                                    font: { size: 12, weight: "normal" },
                                    content: "Risco 0 - Manter",
                                    display: true,
                                    position: "start"
                                },
                            },
                            line1: {
                                type: "line",
                                yMin: 10,
                                yMax: 10,
                                borderColor: "#EDE20C",
                                borderWidth: 2,
                                label: {
                                    backgroundColor: "rgba(240, 240, 240)",
                                    color: "#aa0",
                                    font: { size: 12, weight: "normal" },
                                    content: "Risco 1 - Melhorar",
                                    display: true,
                                    position: "start"
                                },
                            },
                            line2: {
                                type: "line",
                                yMin: 30,
                                yMax: 30,
                                borderColor: "#EC701A",
                                borderWidth: 2,
                                label: {
                                    backgroundColor: "rgba(240, 240, 240)",
                                    color: "#EC701A",
                                    font: { size: 12, weight: "normal" },
                                    content: "Risco 2 - Mudar",
                                    display: true,
                                    position: "start"
                                },
                            },
                            line3: {
                                type: "line",
                                yMin: 50,
                                yMax: 50,
                                borderColor: "#aa0000",
                                borderWidth: 2,
                                label: {
                                    backgroundColor: "rgba(240, 240, 240)",
                                    color: "#aa0000",
                                    font: { size: 12, weight: "normal" },
                                    content: "Risco 3 - Mudar Urgente",
                                    display: true,
                                    position: "start"
                                },
                            },
                        }
                    }

                },
            },
        };
    }

    const chartIqv1 = <HTMLCanvasElement>document.getElementById("chartIqv1");
    new Chart(chartIqv1, getConfig());
    let config = getConfig();
    (config.options.plugins as any).legend = {};
    (config.options.plugins as any).legend.display = false;
    (config.data.datasets[0] as any).pointRadius = 0;
    config.options.plugins.title.font = { size: 12 };
    config.options.plugins.annotation = null as any;
    const chartIqv2 = <HTMLCanvasElement>document.getElementById("chartIqv2");
    new Chart(chartIqv2, config);
};

IndexPage.chartImc = function(chartData: any) {
    if (chartData.labels.length == 0) return;

    const data = {
        labels: chartData.labels,
        datasets: [
            {
                label: "Seu IMC",
                data: chartData.values,
                borderColor: "#7E5475",
                backgroundColor: "#7E5475",
                borderWidth: 1
            },
        ]
    };

    function getConfig(): ChartConfiguration<"line", number[], string> {
        return {
            type: "line",
            data: data,
            //maintainAspectRatio: false,
            options: {
                scales: {
                    y: {
                        min: 15,
                        max: 40,
                        display: false,
                    },
                },
                plugins: {
                    title: {
                        display: true,
                        text: "Minha evolução do IMC",
                        font: { size: 16 }
                    },
                    datalabels: {
                        formatter: function(value: any, context: any) {
                            if (context.dataset.label != "Seu IMC") return null;
                            if (context.dataIndex == 0) return value;
                            if (context.dataset.data[context.dataIndex - 1] != value) return value;
                            return null;
                        },
                        color: "#666f73",
                        anchor: "end",
                        align: "top"
                    },

                    annotation: {
                        annotations: {
                            line0: {
                                type: "line",
                                yMin: 18.5,
                                yMax: 18.5,
                                borderColor: "#00B050",
                                borderWidth: 2,
                                label: {
                                    backgroundColor: "rgba(240, 240, 240)",
                                    color: "#00B050",
                                    font: { size: 12, weight: "normal" },
                                    content: "Abaixo do Peso",
                                    display: true,
                                    position: "start"
                                },
                            },
                            line1: {
                                type: "line",
                                yMin: 25,
                                yMax: 25,
                                borderColor: "#EDE20C",
                                borderWidth: 2,
                                label: {
                                    backgroundColor: "rgba(240, 240, 240)",
                                    color: "#aa0",
                                    font: { size: 12, weight: "normal" },
                                    content: "Peso Normal",
                                    display: true,
                                    position: "start"
                                },
                            },
                            line2: {
                                type: "line",
                                yMin: 30,
                                yMax: 30,
                                borderColor: "#EC701A",
                                borderWidth: 0.5,
                                label: {
                                    backgroundColor: "rgba(240, 240, 240)",
                                    color: "#EC701A",
                                    font: { size: 12, weight: "normal" },
                                    content: "Sobrepeso",
                                    display: true,
                                    position: "start"
                                },
                            },
                            line3: {
                                type: "line",
                                yMin: 35,
                                yMax: 35,
                                borderColor: "#aa0000",
                                borderWidth: 2,
                                label: {
                                    backgroundColor: "rgba(240, 240, 240)",
                                    color: "#aa0000",
                                    font: { size: 12, weight: "normal" },
                                    content: "Obesidade",
                                    display: true,
                                    position: "start"
                                },
                            },
                        }
                    }

                },
            },
        };
    }

    const chartImc1 = <HTMLCanvasElement>document.getElementById("chartImc1");
    new Chart(chartImc1, getConfig());
    let config = getConfig();
    (config.options.plugins as any).legend = {};
    (config.options.plugins as any).legend.display = false;
    (config.data.datasets[0] as any).pointRadius = 0;
    config.options.plugins.annotation = null as any;
    const chartImc2 = <HTMLCanvasElement>document.getElementById("chartImc2");
    new Chart(chartImc2, config);
};

IndexPage.chartWhoqol = function(chartData: any) {
    if (chartData.labels.length == 0) return;

    const data = {
        labels: chartData.labels,
        datasets: [
            {
                label: "Físico",
                data: chartData.dominio1,
                borderColor: "#FC4C0244",
                borderWidth: 1,
                backgroundColor: "#FC4C02",
            },
            {
                label: "Psicológico",
                data: chartData.dominio2,
                borderColor: "#7E547544",
                borderWidth: 1,
                backgroundColor: "#7E5475",
            },
            {
                label: "Relações Sociais",
                data: chartData.dominio3,
                borderColor: "#5B7F9544",
                borderWidth: 1,
                backgroundColor: "#5B7F95",
            },
            {
                label: "Ambiente",
                data: chartData.dominio4,
                borderColor: "#6FA28744",
                borderWidth: 1,
                backgroundColor: "#6FA287",
            }
        ]
    };

    function getConfig(): ChartConfiguration<"bar", number[], string> {
        return {
            type: "bar",
            data: data,
            //maintainAspectRatio: false,
            options: {
                scales: {
                    y: {
                        min: 0,
                        max: 110,
                        display: false,
                    },
                    x: {
                        min: 0,
                    }

                },
                plugins: {
                    title: {
                        display: true,
                        text: "Minha evolução do WHOQOL",
                        font: { size: 16 }
                    },
                    datalabels: {
                        formatter: function(value: any, context: any) {
                            return value + "%";
                        },
                        color: "#000",
                        anchor: "end",
                        align: "top"
                    }
                },
            },
        };
    }

    const chartWhoqol1 = <HTMLCanvasElement>document.getElementById("chartWhoqol1");
    new Chart(chartWhoqol1, getConfig());
    let config = getConfig();
    (config.options as any).maintainAspectRatio = false;
    config.options.scales.y.display = true;
    config.options.scales.y.max = 100;
    config.options.plugins.datalabels.formatter = function(value: any, context: any) { return ""; };
    const chartWhoqol2 = <HTMLCanvasElement>document.getElementById("chartWhoqol2");
    new Chart(chartWhoqol2, config);
}